export function downloadFile(blob: any, fileName: string): void {
  let url
  if (blob.type == 'image/png' || blob.type == 'image/jpg' || blob.type == 'image/jpeg'){
    url = window.URL.createObjectURL(new Blob([blob], {type:'image/jpeg'} ));
  }else {
    url = window.URL.createObjectURL(new Blob([blob], {type:'application/pdf'} ));
  } 
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('href', url);
    link.click();
  }